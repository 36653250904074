import styled from '@emotion/styled';
import copy from 'copy-to-clipboard';
import { ComponentProps, useEffect, useState } from 'react';
import tw from 'twin.macro';

import { useEvent } from '../../hooks';
import { useUser } from '../../providers';
import { Notification } from '../Notification';
import { Portal } from '../Portal';

const Copy = styled.div`
  ${tw`cursor-pointer select-none text-left self-center px-2`}
`;

type InputProps = ComponentProps<'input'> & {
  eventId: number;
  copyButtonOnly?: boolean;
  hideRemoveButton?: boolean;
  customLabel?: string;
  createOrRemoveButtonOnly?: boolean;
};

export const EventCodeCopy = ({
  eventId,
  copyButtonOnly = false,
  hideRemoveButton = false,
  customLabel,
  createOrRemoveButtonOnly = false
}: InputProps) => {
  const [showCopyNotification, setShowCopyNotification] = useState(false);
  const { save, event } = useEvent(eventId);
  const { user } = useUser();
  const [eventCode, setEventCode] = useState(event?.code);
  const isOrganizer = event?.organizer.id === user?.id;

  useEffect(() => {
    setEventCode(event?.code);
  }, [event?.code]);

  const generateEventCode = async () => {
    if (!event) return;

    const randomCode = (Math.random() + 1).toString(36).substring(6);
    event.code = randomCode.toUpperCase();
    await save(event);
    setEventCode(event.code);
  };

  const removeEventCode = async () => {
    if (!event) return;

    event.code = undefined;
    await save(event);
    setEventCode('');
  };

  const label = customLabel ? customLabel : 'Copy';
  const copyButton = (eventCode: string) => (
    <Copy
      className={`text-interaction ${copyButtonOnly && 'pb-2'}`}
      onClick={() => {
        setShowCopyNotification(true);
        copy(eventCode, { format: "text/plain"});
        setTimeout(() => setShowCopyNotification(false), 3000);
      }}
    >
      {label}
    </Copy>
  );

  const createCode = (
    <>
      {isOrganizer && (
        <div
          className="text-interaction cursor-pointer block pb-2"
          onClick={generateEventCode}
        >
          Create Password
        </div>
      )}
    </>
  );

  return (
    <>
      <Portal container="notifications">
        {showCopyNotification && <Notification>Event password copied!</Notification>}
      </Portal>
      {createOrRemoveButtonOnly && (
        <>
          {eventCode ? (
            <div
              onClick={removeEventCode}
              className="text-interaction cursor-pointer block pb-2"
            >
              Remove Password
            </div>
          ) : (
            <>{createCode}</>
          )}
        </>
      )}
      {copyButtonOnly && eventCode && <>{copyButton(eventCode)}</>}
      {!copyButtonOnly && !createOrRemoveButtonOnly && (
        <div>
          {eventCode ? (
            <>
              <div className="flex flex-row w-full justify-center">
                <strong className="pr-1">Event Password:</strong>
                {eventCode}
                <div className="pl-2">{copyButton(eventCode)}</div>
                {!hideRemoveButton && (
                  <div onClick={removeEventCode} className="text-indicating-error cursor-pointer">
                    Remove
                  </div>
                )}
              </div>
              <div className="text-sm text-center text-neutral-char">
                Password will also be available on your event page
              </div>
            </>
          ) : (
            <>{createCode}</>
          )}
        </div>
      )}
    </>
  );
};

export default EventCodeCopy;
