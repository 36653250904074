import styled from '@emotion/styled';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import tw from 'twin.macro';

import { EventLinkCopy, Loader, Menu, ThumbsUp } from '../../components';
import { EventCodeCopy } from '../../components/EventCodeCopy';
import { useDuplicateEventConfirmationModal, useEvent } from '../../hooks';
import { Event, EventGuest, EventRole } from '../../models';
import useUser from '../../providers/UserProvider';
import { logPaymentAnalytic } from '../../utils/AnalyticsUtils';

const Container = styled.div`
  ${tw`flex flex-col text-center space-y-4`}

  & + & {
    ${tw`py-8 mt-2 border-t border-neutral-stone`}
  }
`;

export function FlowConfirmation({
  eventParam,
  wrapInMenu = false
}: {
  eventParam?: Event;
  wrapInMenu?: boolean;
}) {
  const { id } = useParams<{ id: string }>();
  const { event: fetchedEvent } = useEvent(Number(id));
  const [event, setEvent] = useState(id ? fetchedEvent : eventParam);
  const container = useRef<HTMLDivElement>(null);
  const { user } = useUser();
  const eventRole = event?.guests.find((g: EventGuest) => g.guest.id === user?.id)?.role;
  const isCoHost = eventRole === EventRole.Cohost;
  const isOrganizer = event?.organizer.id === user?.id;
  const { openDuplicateEventConfirmation } = useDuplicateEventConfirmationModal(event);

  useEffect(() => {
    if (fetchedEvent) {
      setEvent(fetchedEvent);
    }
  }, [fetchedEvent]);

  useEffect(() => {
    container.current?.scrollIntoView({ block: 'end', behavior: 'auto' });
    logPaymentAnalytic();
  }, []);

  const Content = ({ addPadding = false }: { addPadding?: boolean }) => {
    return event ? (
      <div className={addPadding ? 'p-8' : ''}>
        <Container ref={container}>
          <div className="self-center">
            <ThumbsUp />
          </div>
          <div className="text-2xl font-semibold text-center">All Set!</div>
          <div className="text-sm text-neutral-char">
            If you added guests, their invitations have been sent.
          </div>
        </Container>
        {(isOrganizer || (isCoHost && event.code)) && (
          <Container>
            <div>Make Your Event Private</div>
            <div className="text-sm text-neutral-char mt-0">
              Require guests to enter an Event Password before being able to RSVP and access the event
              page
            </div>
            <EventCodeCopy hideRemoveButton={isCoHost} eventId={event.id} />
          </Container>
        )}
        <Container>
          <div>Publicly Shareable Link</div>
          <div className="text-sm text-neutral-char">Also found on your dashboard</div>
          <EventLinkCopy defaultValue={`${window.location.origin}/api/event/${event.id}/link`} />
        </Container>
        <Container>
          <div className="font-bold">Make a Recurring Series</div>
          <div className="text-sm text-neutral-char mt-0">
            You can duplicate this event which copies the details, needs list, and guest list into
            an editable new event. This means you don't have to start from scratch if you're
            planning out an entire series of events!
          </div>
          <div
            className="text-interaction cursor-pointer select-none"
            onClick={openDuplicateEventConfirmation}
          >
            Duplicate Event
          </div>
        </Container>
      </div>
    ) : (
      <Loader>Loading...</Loader>
    );
  };

  return wrapInMenu ? (
    <Menu displayBackToDashboard displayClose={false}>
      <Content addPadding />
    </Menu>
  ) : (
    <Content />
  );
}

export default FlowConfirmation;
