import styled from '@emotion/styled';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react';
import { useState } from 'react';
import { useModal } from 'react-modal-hook';
import { useAsync } from 'react-use';
import tw from 'twin.macro';

import {
  Button,
  CategorizedClaimableItems,
  itemCategoryIconMap,
  Loader,
  Modal,
  Notification,
  Portal
} from '../../components';
import { Accordion as _Accordion } from '../../components/Accordion';
import { claimItems, clearClaims, getEventItemCategories } from '../../hooks';
import { Event, EventCategory, ItemCategory } from '../../models';
import { usePotluck, useUser } from '../../providers';

const Container = styled.form`
  ${tw`flex flex-col w-full p-8 pt-0 space-y-4`}
`;

interface ClaimItemsProps {
  event: Event;
  isPreviewMode?: boolean;
}

const Accordion = styled(_Accordion)`
  ${tw`w-full border-neutral-stone`}

  & + & {
    ${tw`border-t`}
  }

  .accordion-content {
    ${tw`mb-0`}
  }
`;

const InteractionLink = styled.div<{ useDefaultPointer?: boolean }>`
  ${tw`text-interaction`}
  ${(p) => (p.useDefaultPointer ? tw`cursor-default` : tw`cursor-pointer`)}
`;

export const ClaimItems = observer(({ event, isPreviewMode = false }: ClaimItemsProps) => {
  const categories = getEventItemCategories(event);
  const { user } = useUser();
  const { attending, organizing } = usePotluck();

  const [claims, setClaims] = useState<Record<number, number>>({});
  const [savedClaims, setSavedClaims] = useState(false);
  const isOrganizer = event?.organizer.id === user?.id;

  useAsync(async () => {
    const _claims = Object.entries(claims).map(([eventItemId, quantity]) => ({
      eventItemId: Number(eventItemId),
      quantity
    }));

    if (_claims.length === 0) {
      return;
    }

    setSavedClaims(false);
    const updates = await claimItems(event, _claims);
    setSavedClaims(true);

    runInAction(() => {
      const observableItemsList = isOrganizer
        ? organizing.items(event.id)
        : attending.items(event.id);
      updates.forEach(
        (update) =>
          (observableItemsList.data = observableItemsList.data?.map((item) => {
            return item.id === update.id ? update : item;
          }))
      );
    });
  }, [claims]);

  const [openClearClaimConfirmation, closeClearClaimConfirmation] = useModal(
    () => (
      <Modal onClose={closeClearClaimConfirmation}>
        {({ close }) => (
          <div className="flex flex-col items-center space-y-4">
            <div>
              Are you sure you want to clear all of your item selections and comments? This cannot
              be undone.
            </div>
            <div className="flex flex-col justify-evenly sm:flex-row">
              <Button
                onClick={async () => {
                  await clearClaims(event);

                  // TODO(jesse@fortyau.com) when time allows make this more elegant. This is a bit
                  // hacky, relying on DOM manipulation to make a state change in a lower component.
                  Array.from(document.getElementsByClassName('checked-claim')).forEach((i) =>
                    (i as HTMLElement).click()
                  );

                  setClaims({});
                  close();
                  window.location.reload();
                }}
              >
                Clear Your Selections
              </Button>
              <Button outline className="select-none text-interaction mt-2" onClick={close}>
                Cancel
              </Button>
            </div>
          </div>
        )}
      </Modal>
    ),
    [user, attending, organizing]
  );

  if (!categories) {
    return <Loader>Fetching your items...</Loader>;
  }

  return (
    <>
      <Portal container="notifications">
        {savedClaims && <Notification>Your claims have been saved.</Notification>}
      </Portal>
      <Container onSubmit={(e) => e.preventDefault()}>
        {event.category === EventCategory.Excursion && (
          <div className="mx-auto mb-4">
            <CategorizedClaimableItems
              isPreviewMode={isPreviewMode}
              event={event}
              onClaims={(updates) => setClaims({ ...claims, ...updates })}
              filter={(items) =>
                items.filter((i) => i.name === 'Lodging' || i.name === 'Transportation')
              }
              itemCategory={{ id: 'uncategorized', name: '' } as unknown as ItemCategory}
              className="md:flex-row space-x-0 md:space-y-0 md:space-x-8"
            />
          </div>
        )}
        <div className="flex flex-col items-center">
          <div className="text-2xl font-semibold">Sign Up Sheet</div>
          <div className="text-neutral-ash italic">Please add details where necessary.</div>
        </div>
        <div className={!isPreviewMode ? 'md:mx-20 pb-4' : 'pb-4'}>
          {categories.map((category, index) => (
            <Accordion
              key={category.id}
              isOpen={categories.length === 1 || index === 0}
              header={
                <div className="flex items-center text-lg font-semibold space-x-2">
                  {itemCategoryIconMap[category.name]} <div>{category.name}</div>
                </div>
              }
            >
              <CategorizedClaimableItems
                event={event}
                onClaims={(updates) => setClaims({ ...claims, ...updates })}
                itemCategory={category}
                isPreviewMode={isPreviewMode}
              />
            </Accordion>
          ))}
          <CategorizedClaimableItems
            isPreviewMode={isPreviewMode}
            className={`pt-6 mt-2 ${categories.length ? 'border-t' : ''} border-neutral-stone`}
            event={event}
            onClaims={(updates) => setClaims({ ...claims, ...updates })}
            filter={(items) =>
              items.filter((i) => i.name !== 'Lodging' && i.name !== 'Transportation')
            }
            itemCategory={{ id: 'uncategorized', name: '' } as unknown as ItemCategory}
          />
        </div>
        {claims && (
          <InteractionLink
            useDefaultPointer={isPreviewMode}
            className="self-center select-none"
            onClick={isPreviewMode ? () => {} : openClearClaimConfirmation}
          >
            Clear Your Selections
          </InteractionLink>
        )}
      </Container>
    </>
  );
});

export default ClaimItems;
