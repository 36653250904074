import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { ComponentProps } from 'react';
import tw from 'twin.macro';

const Container = styled.label<{ checked?: boolean }>`
  ${tw`relative block cursor-pointer bg-neutral-ash rounded-xl flex-shrink-0`}

  input[type='checkbox'] {
    height: 0;
    width: 0;
    visibility: hidden;
  }

  ::after {
    ${tw`rounded-full bg-neutral-white`}

    content: '';
    position: absolute;
    top: 0.25rem;
    left: 0.25rem;
    width: 0.75rem;
    height: 60%;

    transition: 0.3s;
  }

  ${(p) => p.checked && tw`bg-primary`}

  ${(p) =>
    p.checked &&
    css`
      ::after {
        left: 86%;
        transform: translateX(-100%);
      }
    `}
`;

export function Switch(props: ComponentProps<'input'>) {
  return (
    <Container className="w-8 h-5" checked={props.checked}>
      <input {...props} type="checkbox" />
    </Container>
  );
}

export default Switch;
