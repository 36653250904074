import styled from '@emotion/styled';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useModal } from 'react-modal-hook';
import { isPossiblePhoneNumber, parsePhoneNumber } from 'react-phone-number-input';
import PhoneInput from 'react-phone-number-input/input';
import tw from 'twin.macro';

import { verifyPhoneWithTwilio } from '../../hooks';
import { EventInvite, EventRole, User } from '../../models';
import { Button } from '../Button';
import { Container as InputContainer, Error, Input } from '../Input';
import { RoleListboxSelect } from '../Input/RoleListboxSelect';
import { Modal } from '../Modal';

const Container = styled.form`
  ${tw`flex flex-col space-y-8`}
`;

const NameInputContainer = styled(Container)`
  margin-top: 1rem !important;
  margin-bottom: 2rem;

  .css-mhducs,
  .css-1ixuyck {
    position: relative;
    margin-bottom: 1rem;
  }

  p, p + div {
    margin-top: .5rem !important;
  }
`;

const Name = styled.div`
  ${tw`flex justify-between space-x-4`}

  input {
    ${tw`flex-1 min-w-0`}
  }
`;

const RoleDescription = styled.div`
  ${tw`text-sm`}

  margin-top: .5rem !important; 
`;

export const FormattedPhoneInput = styled(PhoneInput)`
  ${tw`relative z-10 w-full border rounded-lg border-neutral-ash text-neutral-soil bg-neutral-white p-2`}

  ${(p) => p.invalid && tw`border-2 border-indicating-error focus:outline-indicating-error`}

  height: 3rem;

  ::placeholder {
    ${tw`text-neutral-ash`}
  }

  &:disabled {
    ${tw`border-neutral-stone bg-content-pearl text-neutral-ash`}
  }

  &[type='date'] {
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    position: relative;

    &:focus:before,
    &:not([data-value='']):before {
      display: none;
    }

    &:not(:focus):before {
      content: attr(placeholder);
      pointer-events: none;
      ${tw`absolute top-0 bottom-0 left-0 right-0 p-2 text-neutral-ash bg-neutral-white`}
      width: 80%;
      margin-right: 0.5rem;
    }
  }
`;

interface AddGuestProps {
  editInvite?: EventInvite;
  onAdd: (guest: User, role: EventRole) => void;
  host: User | null | undefined;
}

export function AddGuest({ editInvite, onAdd, host }: AddGuestProps) {
  const {
    register,
    handleSubmit,
    formState: { isDirty, errors },
    watch,
    reset
  } = useForm<User>({
    defaultValues: editInvite
      ? {
          firstName: editInvite.firstName,
          lastName: editInvite.lastName,
          email: editInvite.email,
          phoneNumber: editInvite.phoneNumber
        }
      : { firstName: '', lastName: '', email: '', phoneNumber: '' },
    mode: 'onSubmit'
  });

  const email = watch('email');
  const [selectedRole, setSelectedRole] = useState(EventRole.Guest);

  const initialPhoneNumber = editInvite?.phoneNumber
    ? parsePhoneNumber(editInvite.phoneNumber, 'US')?.number
    : undefined;
  // separate phone number state to be used by the react-phone-number-input
  const [phoneNumHolder, setPhoneNumHolder] = useState(initialPhoneNumber);
  const [invalidNumberError, setInvalidNumberError] = useState(false);

  const [showSelfInviteWarning, closeSelfInviteWarning] = useModal(
    () => (
      <Modal onClose={closeSelfInviteWarning}>
        {({ close }) => (
          <>
            <div className="flex flex-col text-center space-y-4">
              <p>
                You've entered your own email and/or phone number. A host cannot also be a guest on
                the same event.
              </p>
              <Button outline onClick={close}>
                Cancel
              </Button>
            </div>
          </>
        )}
      </Modal>
    ),
    [host]
  );

  return (
    <NameInputContainer
      onSubmit={handleSubmit(async (guest) => {
        if (
          (guest.email && guest.email === host?.email) ||
          (guest.phoneNumber && guest.phoneNumber === host?.phoneNumber)
        ) {
          showSelfInviteWarning();
          return;
        }

        if (guest.phoneNumber) {
          try {
            await verifyPhoneWithTwilio(guest.phoneNumber);
            setInvalidNumberError(false);
          } catch (e) {
            setInvalidNumberError(true);
            return;
          }
        }

        onAdd(guest, selectedRole);
        reset();
        setSelectedRole(EventRole.Guest);
      })}
    >
      <Name>
        <Input
          placeholder="First name"
          {...register('firstName', { required: 'First name is required.' })}
          errors={errors.firstName}
        />
        <Input placeholder="Last name" {...register('lastName')} />
      </Name>
      <div className="flex-1 border-t-2 border-neutral-stone"></div>
      <Input
        placeholder="Email"
        {...register('email', {
          required:
            !phoneNumHolder && !email ? 'Email required if no phone number given.' : undefined,
          pattern: {
            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            message: 'Must be a valid email address.'
          }
        })}
        errors={errors.email}
      />
      <p className="font-bold text-left ml-2.5">or</p>
      <div>
        <InputContainer>
          <FormattedPhoneInput
            invalid={errors.phoneNumber || invalidNumberError}
            {...register('phoneNumber', {
              required:
                !email && !phoneNumHolder ? 'Phone number required if no email given.' : undefined,
              validate: () => {
                return phoneNumHolder
                  ? isPossiblePhoneNumber(phoneNumHolder)
                    ? undefined
                    : 'Must be a valid phone number.'
                  : undefined;
              }
            })}
            country="US"
            placeholder="Text"
            value={phoneNumHolder}
            onChange={setPhoneNumHolder}
          />
          <div>Text</div>
          {errors.phoneNumber && <Error>{errors.phoneNumber.message}</Error>}
          {!errors.phoneNumber && invalidNumberError && (
            <Error relativePosition>This is not a registered U.S. mobile phone number.</Error>
          )}
        </InputContainer>
        <RoleDescription>Invitations and notifications default to SMS if both are provided.</RoleDescription>
      </div>
      <div className="flex-1 border-t-2 border-neutral-stone"></div>
      <RoleListboxSelect
        initialRole={editInvite?.role}
        displayRoleDescriptions={true}
        onChange={(role) => setSelectedRole(role)}
      />

      <Button outline disabled={!editInvite && !isDirty}>
        Save
      </Button>
    </NameInputContainer>
  );
}
