import styled from '@emotion/styled';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import tw from 'twin.macro';

import { useGuestCount } from '../../hooks/useGuestCount';
import { useSendGuestMessageModal } from '../../hooks/useSendGuestMessageModal';
import { useSendRsvpReminderModal } from '../../hooks/useSendRsvpReminderModal';
import { Event, EventGuest, EventRole } from '../../models';
import useUser from '../../providers/UserProvider';
import { EventCodeCopy } from '../EventCodeCopy';
import { EventLinkCopy } from '../EventLinkCopy';

interface EventSettingsProps {
  initialGuestCount?: number;
  event: Event;
  role?: EventRole;
  onCancel: () => void;
  onLeave: () => void;
  onMessageSent: () => void;
  onDuplicateEvent: () => void;
}

const FooterHeader = styled.div`
  ${tw`text-lg text-neutral-soil font-semibold pb-3`}
`;

const MenuItem = styled.div`
  ${tw`text-interaction cursor-pointer select-none text-left self-center px-2 pb-2`}
`;

export function EventSettings({
  event,
  role,
  onCancel,
  onLeave,
  initialGuestCount = 1,
  onMessageSent,
  onDuplicateEvent,
}: EventSettingsProps) {
  const { user } = useUser();
  const [userRole, setRole] = useState<EventRole>();
  const guest = event?.guests.find((g: EventGuest) => g.guest.id === user?.id);
  const [guestCount, setGuestCount] = useState(initialGuestCount);
  const isOrganizer = user?.id === event?.organizer?.id;
  const isCoHost = userRole === EventRole.Cohost;
  const isViewingEvent = window.location.pathname.includes('/event');
  const { openRsvpReminderModal } = useSendRsvpReminderModal(event.id, onMessageSent);
  const { openGuestMessageModal } = useSendGuestMessageModal(event.id, onMessageSent);

  const handleGuestCountUpdate = (count: number) => {
    setGuestCount(count);
    if (guest) {
      guest.guestCount = count;
    }
  };

  const { openGuestCount } = useGuestCount(event.id, user?.id, handleGuestCountUpdate, guestCount);

  return (
    <div>
      {(isOrganizer || isCoHost) && (
        <div className="flex justify-evenly flex-wrap">
          <div className="flex flex-col">
            <FooterHeader>Messaging & Sharing</FooterHeader>
              {event.guests.length > 0 && (
                <MenuItem onClick={openGuestMessageModal}>Send Guest Message</MenuItem>
              )}
              <MenuItem onClick={openRsvpReminderModal}>Send RSVP Reminder</MenuItem>
            
              <EventLinkCopy
                customLabel="Copy Sharable Link"
                copyButtonOnly defaultValue={`${window.location.origin}/api/event/${event.id}/link`}
              ></EventLinkCopy>

              <EventCodeCopy key={event.code} createOrRemoveButtonOnly eventId={event.id} />

              {event.code && (
                <div>Event Password: {event.code}</div>
              )}
          </div>
          <div className="flex flex-col">
            <FooterHeader>Editing</FooterHeader>
            {(isOrganizer || isCoHost) && (
              <>
                <Link
                  to={isViewingEvent ? `/event/${event.id}/details` : `/edit/${event.id}/details`}
                  className="select-none text-interaction block pb-2"
                >
                  Edit Date/Time/Location
                </Link>
                <Link
                  to={isViewingEvent ? `/event/${event.id}/items` : `/edit/${event.id}/items`}
                  className="select-none text-interaction block pb-2"
                >
                  Edit Event Page
                </Link>
                <Link
                  to={isViewingEvent ? `/event/${event.id}/guests` : `/edit/${event.id}/guests`}
                  className="select-none text-interaction block pb-2"
                >
                  Edit Guest List
                </Link>
              </>
            )}
            {isOrganizer && (
              <div
                className="cursor-pointer select-none text-interaction block pb-2"
                onClick={onDuplicateEvent}
              >
                Duplicate Event
              </div>
            )}
          </div>
        </div>
      )}
      <div className="space-y-4 inline-flex flex-col pt-10">
        {event.allowPlusOnes && guest && (
          <div>
            <div className="text-interaction cursor-pointer" onClick={openGuestCount}>
              Edit Your Party Size
            </div>
          </div>
        )}
        {role === EventRole.Organizer ? (
          <div className="text-indicating-error cursor-pointer" onClick={onCancel}>
            CANCEL EVENT
          </div>
        ) : (
          <div className="text-interaction cursor-pointer" onClick={onLeave}>
            I can no longer attend this event 😢
          </div>
        )}
      </div>
    </div>
  );
}

export default EventSettings;
