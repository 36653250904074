import 'react-quill/dist/quill.snow.css';
import '../../react-quill-indent-override.css';

import styled from '@emotion/styled';
import { RouterHistory } from '@sentry/react/types/reactrouter';
import parse from 'html-react-parser';
import { observer } from 'mobx-react';
import { ComponentProps, useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useModal } from 'react-modal-hook';
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import { NavLink, Route, Switch } from 'react-router-dom';
import { useAsync } from 'react-use';
import tw from 'twin.macro';

import {
  Banner as BannerComponent,
  BannerProps,
  BrandLogo,
  Button,
  CircleChat,
  CircleChatWithNotification,
  CircleEdit,
  CircleShare,
  Data,
  Divider,
  EventLinkCopy,
  EventRSVPs,
  EventSettings,
  FadeIn,
  fadeInAnimation,
  FormattedPhoneInput,
  Items,
  Loader,
  Menu,
  MenuProps,
  Modal,
  Notification,
  Popup,
  Portal
} from '../../components';
import AddressDisplay from '../../components/AddressDisplay/AddressDisplay';
import { EventCodeCopy } from '../../components/EventCodeCopy';
import { EventEditMenu } from '../../components/EventEditMenu';
import EventImportantActionButton from '../../components/EventImportantActionButton/EventImportantActionButton';
import { Container as InputContainer, Error, Input } from '../../components/Input';
import { Timestamp } from '../../components/Timestamp/Timestamp';
import { useDuplicateEventConfirmationModal, useEvent, verifyPhoneWithTwilio } from '../../hooks';
import { useGuestCount } from '../../hooks/useGuestCount';
import { useSendGuestMessageModal } from '../../hooks/useSendGuestMessageModal';
import { useSendRsvpReminderModal } from '../../hooks/useSendRsvpReminderModal';
import { Event, Event as EventModel, EventGuest, EventInvite, EventRole, User } from '../../models';
import { fetcher, usePotluck, useUser } from '../../providers';
import { Chat } from './Chat';
import { ClaimItems } from './ClaimItems';
import { Editor } from './Editor';

const Page = styled.div`
  ${tw`flex flex-col flex-1 flex-grow w-full max-w-4xl mx-auto`}
`;

const Container = styled(FadeIn)<{ stretch?: boolean }>`
  ${tw`w-full mx-auto bg-neutral-white pb-12 flex-1`}

  ${(p) => !p.stretch && tw`rounded-br-3xl`}
`;

const QuantityInput = styled.div`
  ${tw`text-right pl-4`}

  max-width: 5rem;
`;

const HeaderContainer = styled.div`
  ${tw`overflow-hidden bg-neutral-stone bg-opacity-50 rounded-br-3xl`}
  height: 56.25vw;
  width: 100%;

  @media (min-width: 896px) {
    height: 31.156rem;
  }
`;

const Header = styled.div<{ background?: string }>`
  ${tw`flex flex-col items-end justify-center bg-center bg-no-repeat bg-cover bg-secondary-600`}
  height: 56.25vw;
  width: 100%;

  @media (min-width: 896px) {
    height: 31.156rem
  }

  background-image: ${(p) => p.background && `url(${p.background})`};
  transition: background 0.1s ease-in;

  ${(p) => p.background && fadeInAnimation(0.3)}
`;

const NameInputContainer = styled(InputContainer)`
  margin-top: 1rem !important;
  margin-bottom: 2rem;

  .css-mhducs,
  .css-1ixuyck {
    position: relative;
    margin-bottom: 1rem;
  }
`;

const Link = styled(NavLink)`
  ${tw`border-b-4 border-secondary border-opacity-0 text-neutral-soil`}

  &.active {
    ${tw`border-opacity-100`}
  }
`;

const InlineMenu = styled((props: MenuProps) => <Menu {...props} />)`
  *:last-of-type {
    ${tw`max-w-xs`}
  }
`;

const MenuItem = styled.div`
  ${tw`text-interaction cursor-pointer select-none text-left self-center px-2 pb-2`}
`;

const Banner = styled((props: ComponentProps<'div'> & BannerProps) => (
  <BannerComponent {...props} />
))`
  ${tw`p-0`}

  .logo {
    ${tw`ml-6`}

    svg {
      ${tw`text-neutral-white`}
    }
  }

  .menu-icon {
    ${tw`p-3 bg-primary rounded-bl-2xl cursor-pointer`}
  }
`;

const Content = styled.div`
  ${tw`flex flex-col w-full max-w-xl px-8 mx-auto text-center space-y-8 mb-4`}
`;

const EventPageMessageContainer = styled.div<{ isPreviewMode?: boolean }>`
  ${tw`flex flex-col w-full px-8 text-left space-y-8`}
  ${(p) => !p.isPreviewMode && tw`md:px-28`}
`;

const Separator = styled.div`
  ${tw`pt-6 mt-2 border-t border-neutral-stone`}
`;

interface UnregisteredContentProps {
  event: Event;
  message?: string;
  onSignInClicked: () => void;
}

const RsvpContent = ({ event, message, onSignInClicked }: UnregisteredContentProps) => {
  const userProvider = useUser();
  const { user } = userProvider;
  const isLoggedIn = !!user?.id;

  return (
    <>
      {message && (
        <>
          <div className="flex flex-col w-auto mx-8 md:mx-28 text-left py-3">
            {parse(message)}
            <div className="w-auto mt-3">
              <Separator />
            </div>
          </div>
        </>
      )}
      <Content>
        <div className="text-2xl">RSVP to see needs and details</div>
        {!isLoggedIn && (
          <>
            <div className="flex justify-center">
              <Button outline onClick={onSignInClicked}>
                Sign In
              </Button>
            </div>
            <div className="flex items-center space-x-4 px-7">
              <div className="flex-1 border-t-2 border-neutral-stone"></div>
              <b>or</b>
              <div className="flex-1 border-t-2 border-neutral-stone"></div>
            </div>
          </>
        )}
        <NewUserCapture event={event} includeNewUserForm={!isLoggedIn} existingUser={user} />
      </Content>
    </>
  );
};

interface NewUserCaptureProps {
  event: Partial<EventModel>;
  existingUser?: User | null | undefined;
  includeNewUserForm: boolean;
}

const NewUserCapture = ({
  event,
  existingUser = undefined,
  includeNewUserForm
}: NewUserCaptureProps) => {
  const { signUp } = useUser();
  const {
    register,
    handleSubmit,
    getValues,
    setError,
    formState: { errors },
    watch
  } = useForm({
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      guestCount: 1 // not part of the User model -- passing along to be saved separately with the user on event_guest
    },
    mode: 'onSubmit'
  });

  // separate phone number state to be used by the react-phone-number-input
  const [phoneNumHolder, setPhoneNumHolder] = useState<string | undefined>(undefined);
  const [invalidNumberError, setInvalidNumberError] = useState(false);
  const [rsvpResponse, setRsvpResponse] = useState('no');
  const email = watch('email');

  return (
    <Content>
      <form
        onSubmit={handleSubmit(async (user) => {
          if (existingUser) {
            await fetcher(`/api/event/${event.id}/rsvp`, {
              method: 'POST',
              body: JSON.stringify({ guestCount: 0, response: rsvpResponse })
            });
            return;
          }

          if (user.phoneNumber) {
            try {
              await verifyPhoneWithTwilio(user.phoneNumber);
              setInvalidNumberError(false);
            } catch (e) {
              setInvalidNumberError(true);
              return;
            }
          }

          try {
            await signUp(
              existingUser ? existingUser : (user as unknown as User),
              undefined,
              undefined,
              `${event.id}`,
              rsvpResponse,
              user.guestCount,
              true
            );
          } catch (e) {
            //@ts-ignore
            const result = await e.json();
            if (result.error.includes('number')) {
              setError('phoneNumber', { message: result.error });
            } else {
              setError('email', { message: result.error });
            }
          }
        })}
      >
        {includeNewUserForm && (
          <>
            <NameInputContainer>
              <Input
                className={!errors.firstName ? 'mb-2' : ''}
                placeholder="First Name (required)"
                errors={errors.firstName}
                {...register('firstName', { required: 'Your first name is required.' })}
              ></Input>
              <Input
                className={!errors.lastName ? 'mb-2' : ''}
                placeholder="Last Name"
                {...register('lastName')}
                errors={errors.lastName}
              ></Input>
              <p className="text-left text-neutral-char">So the host knows who you are...</p>
            </NameInputContainer>
            <NameInputContainer>
              <Input
                className={!errors.email ? 'mb-2' : ''}
                placeholder="Email"
                {...register('email', {
                  required:
                    !phoneNumHolder && !getValues('email')
                      ? 'Email required if no phone number given.'
                      : undefined,
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: 'Must be a valid email address.'
                  }
                })}
                errors={errors.email}
              />
              <p className="font-bold text-left ml-2.5">or</p>
              <InputContainer className={!errors.phoneNumber ? 'mb-2 mt-2' : 'mt-2'}>
                <FormattedPhoneInput
                  invalid={errors.phoneNumber || invalidNumberError}
                  {...register('phoneNumber', {
                    validate: () => {
                      return phoneNumHolder
                        ? isPossiblePhoneNumber(phoneNumHolder)
                          ? undefined
                          : 'Must be a valid phone number.'
                        : undefined;
                    },
                    required:
                      !email && !phoneNumHolder
                        ? 'Phone number required if no email given.'
                        : undefined
                  })}
                  country="US"
                  placeholder="Phone"
                  value={phoneNumHolder}
                  onChange={setPhoneNumHolder}
                />
                <div>Phone</div>
                {errors.phoneNumber && <Error relativePosition>{errors.phoneNumber.message}</Error>}
                {!errors.phoneNumber && invalidNumberError && (
                  <Error relativePosition>This is not a registered U.S. mobile phone number.</Error>
                )}
              </InputContainer>
              <p className="text-left text-neutral-char">
                ... and you can receive event notifications
              </p>
            </NameInputContainer>
            {event.allowPlusOnes && (
              <Container className="flex justify-start">
                <div>
                  <div className="text-lg text-left font-semibold">How many in your party?</div>
                  <div className="text-neutral-ash italic text-right">Including you</div>
                </div>
                <QuantityInput>
                  <Input
                    {...register('guestCount', { required: 'Guest count is required' })}
                    type="number"
                    min="1"
                    onFocus={(e) => e.target.select()}
                  />
                </QuantityInput>
              </Container>
            )}
          </>
        )}
        <div className="flex flex-col justify-evenly sm:flex-row">
          <Button
            outline
            onClick={() => {
              setRsvpResponse('no');
            }}
            type="submit"
          >
            No
          </Button>
          <Button
            className="mt-2"
            onClick={() => {
              setRsvpResponse('yes');
            }}
            type="submit"
          >
            Yes
          </Button>
        </div>
      </form>
    </Content>
  );
};

interface EventCodeEntryProps {
  event: Partial<EventModel>;
  onSubmit: (value: string) => void;
  hasError: boolean;
}

const EventCodeEntry = ({ event, onSubmit, hasError }: EventCodeEntryProps) => {
  let currentInputValue = '';

  return (
    <Content>
      <p className="text-neutral-char text-left">Enter the Event Password to access this event.</p>
      <InputContainer>
        <Input
          onChange={(e) => {
            currentInputValue = e.target.value;
          }}
        />
        {hasError && <Error>Incorrect Event Password</Error>}
      </InputContainer>
      <Button onClick={() => onSubmit(currentInputValue)}>Submit</Button>
    </Content>
  );
};

export const EventPageBody = observer(
  ({
    event,
    isPreviewMode = false,
    path,
    router,
    onCloseEventPreview
  }: {
    event: EventModel;
    isPreviewMode?: boolean;
    path: string;
    router: RouterHistory | undefined;
    onCloseEventPreview?: () => void;
  }) => {
    const { save, leave, role: getRole } = useEvent(Number(event.id));
    const [userRole, setRole] = useState<EventRole>();
    const userProvider = useUser();
    const { user } = userProvider;
    const isOrganizer = user?.id === event?.organizer?.id;
    const isCoHost = userRole === EventRole.Cohost;
    const guest = event?.guests.find((g: EventGuest) => g.guest.id === user?.id);
    const { openDuplicateEventConfirmation } = useDuplicateEventConfirmationModal(event);
    const [showMessageSent, setShowMessageSent] = useState(false);

    const handleMessageSentDisplay = () => {
      setShowMessageSent(true);
      setTimeout(() => setShowMessageSent(false), 3000);
    };

    const { openRsvpReminderModal } = useSendRsvpReminderModal(event.id, handleMessageSentDisplay);
    const { openGuestMessageModal } = useSendGuestMessageModal(event.id, handleMessageSentDisplay);

    const isGuestOrOrganizer = useCallback((): boolean => {
      const guest = event?.guests.find((g: EventGuest) => g.guest.id === user?.id);
      return !!guest || isOrganizer;
    }, [event, isOrganizer, user]);

    const isInvited = useCallback((): boolean => {
      const invitee = event?.invites.find(
        (i: EventInvite) => i.user?.id === user?.id && !!user?.id
      );
      return !!invitee;
    }, [event, user]);

    const isLoggedIn = !!user?.id;
    const [askForEventCode, setAskForEventCode] = useState(
      event?.code && (!isLoggedIn || !isGuestOrOrganizer())
    );
    const [eventCodeFromUser, setEventCodeFromUser] = useState('');

    useEffect(() => {
      if (event?.code && (!isLoggedIn || !isGuestOrOrganizer())) {
        setAskForEventCode(eventCodeFromUser.toUpperCase() !== event.code.toUpperCase());
      }
    }, [eventCodeFromUser, event?.code, isGuestOrOrganizer, isLoggedIn]);

    const { organizing, attending } = usePotluck();
    const [isOpen, setIsOpen] = useState(false);
    const openMenu = useCallback(() => {
      if (event) {
        router?.push(`/event/${event.id}`);
      }
      setIsOpen(true);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [event]);

    const closeMenu = useCallback(() => setIsOpen(false), []);
    const [showEditMenu, setShowEditMenu] = useState(false);
    const [showShareMenu, setShowShareMenu] = useState(false);
    const [guestCount, setGuestCount] = useState(!!guest?.guestCount ? guest?.guestCount : 1);

    const { openGuestCount } = useGuestCount(event?.id, user?.id, setGuestCount, guestCount);

    const toggleEditMenu = () => {
      setShowEditMenu(!showEditMenu);
      setShowShareMenu(false);
    };

    const toggleShareMenu = () => {
      setShowShareMenu(!showShareMenu);
      setShowEditMenu(false);
    };

    useEffect(() => {
      if (guest) {
        setGuestCount(!!guest?.guestCount ? guest?.guestCount : 1);
      }
    }, [guest]);

    const [openConfirmCancelEvent, closeConfirmCancelEvent] = useModal(() => {
      if (!event) {
        return null;
      }
      const eventCancelText = `Are you sure you want to cancel <b>${event.name}</b>?<br>All attendees will be notified.<br>This cannot be undone.`;

      return (
        <Modal fullWidth={false} onClose={closeConfirmCancelEvent}>
          {({ close }) => (
            <div className="flex flex-col text-center space-y-4">
              <p dangerouslySetInnerHTML={{ __html: eventCancelText }}></p>
              <div className="flex flex-col justify-evenly sm:flex-row">
                <Button
                  onClick={async () => {
                    close();
                    await organizing.cancel(event.id);
                    router?.push('/');
                  }}
                >
                  Cancel Event
                </Button>
                <Button className="mt-2" outline onClick={close}>
                  I've changed my mind
                </Button>
              </div>
            </div>
          )}
        </Modal>
      );
    }, [event]);

    const [openConfirmLeave, closeConfirmLeave] = useModal(() => {
      if (!event) {
        return null;
      }

      return (
        <Modal onClose={closeConfirmLeave}>
          {({ close }) => (
            <div className="flex flex-col text-center space-y-4">
              <p>
                Are you sure you want to leave <b>{event.name}</b>?
              </p>
              <div className="flex flex-col justify-evenly sm:flex-row">
                <Button
                  onClick={async () => {
                    await leave(event);
                    close();
                    router?.push('/invite-decline');
                  }}
                >
                  Leave Event
                </Button>
                <Button className="mt-2" outline onClick={close}>
                  I've changed my mind
                </Button>
              </div>
            </div>
          )}
        </Modal>
      );
    }, [event]);

    useAsync(async () => {
      if (event && user && isGuestOrOrganizer() && !isPreviewMode) {
        setRole(await getRole(event.id));
      }

      if (event && event?.allowPlusOnes && guest && !guest.guestCount) {
        openGuestCount();
      }
    }, [event, user]);

    if (!event) {
      return <Loader>Fetching event details...</Loader>;
    }

    const getFormattedTime = () => {
      let time = 'TBD';
      if (event.startTime) {
        //@ts-ignore
        time = formattedStartTime;

        if (event.endTime) {
          time += ' - ';
          if (eventStartsAndEndsOnSameDay) {
            time += new Date(event.endTime).toLocaleTimeString('en', {
              hour12: true,
              timeStyle: 'short'
            });
          } else {
            time += formattedEndTime;
          }
        }
      }
      return time;
    };

    const datesAreOnSameDay = (first: Date, second: Date) =>
      first.getFullYear() === second.getFullYear() &&
      first.getMonth() === second.getMonth() &&
      first.getDate() === second.getDate();

    const eventStartsAndEndsOnSameDay =
      event.startTime &&
      event.endTime &&
      datesAreOnSameDay(new Date(event.startTime), new Date(event.endTime));

    const formattedEndTime =
      event.endTime &&
      new Date(event.endTime).toLocaleString('en', {
        hour12: true,
        dateStyle: 'long',
        timeStyle: 'short'
      });

    const formattedStartTime =
      event.startTime &&
      new Date(event.startTime).toLocaleString('en', {
        hour12: true,
        dateStyle: 'long',
        timeStyle: 'short'
      });

    // hardcoding to always show notification to bring attention to the unused chat feature
    const hasNotification = true;

    const PreviewBanner = () => (
      <div className="justify-between sticky top-0 items-center flex h-20 bg-secondary-600 w-full z-10 p-6">
        <div>
          <p className="text-left text-3xl text-neutral-white leading-6 font-semibold">
            Preview Mode
          </p>
          <p className="text-sm text-neutral-white font-normal text-left pt-1">
            Interactions disabled
          </p>
        </div>
        <Button onClick={onCloseEventPreview} inline allWhite outline width={72}>
          Exit
        </Button>
      </div>
    );

    const coverImage = (
      <HeaderContainer>
        <Header background={event.coverImageUrl}>
          <Banner
            className="flex justify-end"
            onClick={openMenu}
            disabled={!isLoggedIn || isPreviewMode}
          />
          {!isPreviewMode && (
            <EventImportantActionButton
              containerClassNames="pl-2 sm:pl-8 -mt-8 z-30"
              event={event}
              user={user}
            />
          )}
          {!isLoggedIn && <Timestamp event={event} />}
          <div className="flex flex-1 items-end">
            <div className="flex flex-1 flex-col">
              <svg viewBox="0 0 8 8">
                <path d="M0 8C4.41846 8 8 4.41833 8 0V8H0Z" />
              </svg>
              <div className="h-6 bg-transparent rounded-tl-3xl" />
            </div>
          </div>
        </Header>
      </HeaderContainer>
    );

    const topSection = (
      <div className="pt-2 pb-4 space-y-2 bg-neutral-white">
        <div className={`mx-8 ${!isPreviewMode && 'md:mx-28'}`}>
          <div className="flex space-x-4 items-center">
            <div className="text-2xl pt-4 font-semibold flex-1">{event.name}</div>
            {!isPreviewMode && isLoggedIn && isGuestOrOrganizer() && (
              <>
                <div className="flex pl-4 items-center mt-4">
                  <div className="relative">
                    <Button
                      className="mt-5 bg-neutral-white inline"
                      outline
                      inline
                      onClick={() => router?.push(`/event/${event.id}/chat`)}
                    >Event Chat
                    </Button>
                    <div
                      className="absolute top-5 right-0 h-3 w-3 b
                        rounded-full border-2 bg-indicating-success
                        border-indicating-success"
                    >
                      &nbsp;
                    </div>
                  </div>
                  <EventEditMenu
                    className="right-36 top-4"
                    showViewOption={false}
                    userId={user.id}
                    isOrganizer={isOrganizer}
                    onClose={() => setShowEditMenu(false)}
                    visible={showEditMenu}
                    event={event}
                    onCancelEvent={openConfirmCancelEvent}
                    onDuplicateEvent={openDuplicateEventConfirmation}
                  />
                </div>
              </>
            )}
          </div>
          {!askForEventCode && (
            <>
              {event.address && <AddressDisplay address={event.address} />}
              {event.startTime && <div className="text-sm">{getFormattedTime()}</div>}
              <Divider />
            </>
          )}
        </div>
      </div>
    );

    return (
      <Page>
        <InlineMenu goBackOnClose open={isOpen} onClose={closeMenu}>
          <Items>
            {isLoggedIn && (
              <div
                className="flex cursor-pointer space-x-8 text-interaction"
                onClick={() => {
                  closeMenu();
                  router?.push(`/event/${event.id}/insights`);
                }}
              >
                <Data className="ml-auto mr-4 text-xl cursor-pointer" />
                Insights
              </div>
            )}
          </Items>
        </InlineMenu>
        <Container stretch={!isLoggedIn}>
          <Portal container="notifications">
            {showMessageSent && <Notification>Message sent!</Notification>}
          </Portal>
          {isPreviewMode && (
            <div className="flex flex-col">
              <PreviewBanner />
              {coverImage}
              {topSection}
              <EventPageMessageContainer isPreviewMode={isPreviewMode} className="ql-editor">
                {event.eventPageMessage && (
                  <>
                    <div>{parse(event.eventPageMessage)}</div>
                    {event.items.length > 0 && <Separator />}
                  </>
                )}
              </EventPageMessageContainer>

              {event.id && event.items.length > 0 && <ClaimItems isPreviewMode event={event} />}
            </div>
          )}
          {!isPreviewMode && (
            <Switch>
              <Route path={`${path}/edit`}>
                <Menu>
                  <Editor event={event} onChange={async (event) => void (await save(event))} />
                </Menu>
              </Route>
              <Route path={path}>
                <div className="flex flex-col">
                  {coverImage}
                  <Route path={path}>{topSection}</Route>
                  <div className="relative bg-neutral-white">
                    <div className="flex flex-1 flex-col w-full overflow-visible">
                      {askForEventCode ? (
                        <EventCodeEntry
                          event={event}
                          onSubmit={setEventCodeFromUser}
                          hasError={eventCodeFromUser !== '' && eventCodeFromUser !== event.code}
                        />
                      ) : (
                        <>
                          {!isLoggedIn || !isGuestOrOrganizer() ? (
                            <RsvpContent
                              event={event}
                              message={event.eventPageMessage}
                              onSignInClicked={() => router?.push(`/sign-in?event-id=${event.id}`)}
                            />
                          ) : (
                            <>
                              <Route path={`${path}/chat`}>
                                <Menu
                                  header={<div className="text-xl font-semibold">Event Chat</div>}
                                  goBackOnClose
                                >
                                  <Chat event={event} />
                                </Menu>
                              </Route>
                              <Route path={`${path}/insights`}>
                                <Menu
                                  header={
                                    <div className="flex-1 text-xl font-semibold">
                                      Event Insights
                                    </div>
                                  }
                                  goBackOnClose
                                >
                                  <div className="space-y-12 px-12 pb-12">
                                    <div className="space-y-4">
                                      <EventRSVPs
                                        initialGuestCount={guestCount}
                                        event={event}
                                        role={userRole}
                                        onEventUpdate={async () =>
                                          isOrganizer
                                            ? organizing.updateItemsInCategories(event?.id)
                                            : attending.updateItemsInCategories(event?.id)
                                        }
                                      />
                                    </div>
                                  </div>
                                </Menu>
                              </Route>
                              <Route path={path}>
                                <EventPageMessageContainer
                                  isPreviewMode={isPreviewMode}
                                  className="ql-editor"
                                >
                                  {event.eventPageMessage && (
                                    <>
                                      <div>{parse(event.eventPageMessage)}</div>
                                      {event.items.length > 0 && <Separator />}
                                    </>
                                  )}
                                </EventPageMessageContainer>

                                {event.items.length > 0 && <ClaimItems event={event} />}
                              </Route>
                            </>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </Route>
            </Switch>
          )}
        </Container>
        {!isPreviewMode && isLoggedIn && (
          <div className="w-full flex flex-col self-center text-center relative">
            <div className="h-6 w-6 bg-neutral-white z-10 absolute top-0 left-0" />
            <div className="space-y-12 p-8 bg-content-spring-wood z-20">
              {isGuestOrOrganizer() && (
                <div>
                  <div className="flex justify-center mb-6">
                    <Button
                      inline
                      onClick={() => router?.push(`/event/${event.id}/insights`)}
                    >RSVP Details
                    </Button>
                  </div>
                  <EventSettings
                  event={event}
                role={userRole}
                onCancel={openConfirmCancelEvent}
                onLeave={openConfirmLeave}
                initialGuestCount={guest?.guestCount}
                    onMessageSent={handleMessageSentDisplay}
                    onDuplicateEvent={openDuplicateEventConfirmation}
                  />
                </div>
              )}
              <div className="flex justify-center">
                <div className="h-4">
                  <Link to="/">
                    <BrandLogo className="max-h-full" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        )}
      </Page>
    );
  }
);

export default EventPageBody;
