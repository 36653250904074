import styled from '@emotion/styled';
import tw from 'twin.macro';

import { Card, Loader, Switch } from '../../components';
import { useUser } from '../../providers';
import { PrivacySettings } from '../PrivacySettings';

const Container = styled.div`
  ${tw`space-y-8 pb-16`}
  @media (min-width: 1024px) {
    max-width: 21.375rem;
  }
`;

const Header = styled.div`
  ${tw`font-semibold`}
  font-size: 1.375rem;
`;

const Divider = styled.div`
  ${tw`opacity-50 bg-neutral-stone`}
  height: 0.125rem;
`;

interface NotificationRuleProps {
  label?: string;
  isExcluded?: boolean;
  displayLeadingText?: boolean;
  onToggle?: () => void;
}

const NotificationRule = ({ label, isExcluded, displayLeadingText, onToggle }: NotificationRuleProps) => {
  return (
    <div className="flex justify-between space-x-16">
      <div className="font-semibold">{displayLeadingText && 'Notify when'} {label}.</div>
      <Switch checked={!isExcluded} onChange={onToggle} />
    </div>
  );
};

const HostNotificationProperties = {
  guest_rsvp: 'a guest RSVPs',
  event_summary: 'Once daily event updates',
  chat_update: 'someone uses the event chat',
  moments_page: 'a Moments Page goes live',
  photo_uploaded: 'a new photo is uploaded',
}

const GuestNotificationProperties = {
  moments_page: 'a Moments Page goes live',
  photo_uploaded: 'a new photo is uploaded'
}


export function NotificationSettings() {
  const { user, update } = useUser();

  if (!user) {
    return <Loader>Fetching your notification settings...</Loader>;
  }

  const toggleHostNotificationRule = async (property: string) => {
    await update({
      ...user,
      hostNotificationExclusions: user.hostNotificationExclusions.some((r) => r === property)
        ? user.hostNotificationExclusions.filter((r) => r !== property)
        : [...user.hostNotificationExclusions, property]
    });
  };
  const toggleGuestNotificationRule = async (property: string) => {
    await update({
      ...user,
      guestNotificationExclusions: user.guestNotificationExclusions.some((r) => r === property)
        ? user.guestNotificationExclusions.filter((r) => r !== property)
        : [...user.guestNotificationExclusions, property]
    });
  };

  return (
    <Container>
      <PrivacySettings />
      <Header className="mb-4 text-center">Notification Settings</Header>
      <div className="mb-8">
        Some notifications are fully automated. For instance, if you update the date, time, or location of your event, all guests will receive an automatic update with that information.
        <br /><br />
        The below notifications are ones you are able to turn on or off for yourself or your guests, as needed.
      </div>
      <Container>
        <Card>
          <Header className="mb-4">What YOU receive</Header>
          <div className="flex flex-col space-y-6">
            {Object.entries(HostNotificationProperties).map(([property, label]) => (
              <NotificationRule
                key={property}
                label={label}
                isExcluded={user.hostNotificationExclusions.some((p) => p === property)}
                displayLeadingText={property !== 'event_summary'}
                onToggle={() => toggleHostNotificationRule(property)}
              />
            ))}
          </div>
        </Card>
        <Card inline>
          <Header className="mb-4">What your GUESTS receive</Header>
          <div className="flex flex-col space-y-6">
            {Object.entries(GuestNotificationProperties).map(([property, label]) => (
              <NotificationRule
                key={property}
                label={label}
                isExcluded={user.guestNotificationExclusions.some((p) => p === property)}
                displayLeadingText={true}
                onToggle={() => toggleGuestNotificationRule(property)}
              />
            ))}
          </div>
        </Card>
      </Container>
    </Container>
  );
}

export default NotificationSettings;