import styled from '@emotion/styled';
import { capitalize } from 'lodash';
import { ComponentProps, ReactNode, useState } from 'react';
import { useModal } from 'react-modal-hook';
import tw from 'twin.macro';

import { Event, EventItem, EventItemClaim } from '../../models';
import { fetcher } from '../../providers';
import { Accordion } from '../Accordion';
import { FadeIn } from '../animations';
import { Button } from '../Button';
import { Item } from '../Item';
import { ItemCategories } from '../ItemCategories';
import { ItemTemplatesButton } from '../ItemTemplatesButton';
import { Modal } from '../Modal';

interface ItemCreatorProps {
  event: Event;
  item?: EventItem;
  onSave: (item: EventItem) => void;
  openCreatorOption?: ReactNode;
}

const SaveButton = styled(Button)`
  ${tw`min-w-0 px-14`}
`;

const QtyErrorText = styled.div`
  ${tw`text-sm text-indicating-error mb-2 pt-1`}
`;

export const Error = styled(FadeIn)`
  ${tw`absolute z-0 w-full px-2 py-1 pt-3 -mt-2 text-sm bg-indicating-error bg-opacity-50 rounded-b-md`}
`;

export const ItemCreator = ({
  event,
  item,
  onSave,
  onTemplateSave,
  openCreatorOption
}: Omit<ComponentProps<'div'>, 'onChange'> &
  ItemCreatorProps & { onTemplateSave?: (event: Event) => void }) => {
  const [openCreator, closeCreator] = useModal(
    () => (
      <Modal
        padContentAtSmallWidths
        onClose={closeCreator}
        header={<div className="text-2xl font-semibold">What should guests bring or do?</div>}
        fullHeight
      >
        {({ close }) => (
          <ItemCreatorDetails
            onClose={() => close()}
            onSave={(i) => onSave(i)}
            event={event}
            item={item}
          />
        )}
      </Modal>
    ),
    [item]
  );

  return (
    <>
      <div className="self-center inline-flex" onClick={() => openCreator()}>
        {openCreatorOption}
      </div>
      {onTemplateSave && (
        <div>
          <ItemTemplatesButton
            event={event}
            onChange={(event) => {
              onTemplateSave(event);
            }}
          />
        </div>
      )}
    </>
  );
};

interface ItemCreatorDetailsProps extends ItemCreatorProps {
  onClose: () => void;
}

export const ItemCreatorDetails = ({
  item: _item,
  event,
  onClose,
  onSave
}: ItemCreatorDetailsProps) => {
  const [item, setItem] = useState(
    _item ? _item : ({ name: '', claims: [] as EventItemClaim[] } as EventItem)
  );
  const [showCategoryError, setShowCategoryError] = useState(false);
  const itemHasName = !!item.name;

  const claimedQty = item.claims?.reduce((qty, i) => qty + i.quantity, 0);
  const invalidQty = () => (item.quantity ?? 0) < claimedQty;

  return (
    <div>
      <Item
        className={`${invalidQty() ? '' : 'pb-4 mb-4'} `}
        item={item}
        event={event}
        onChange={(i) => setItem(i)}
      />

      {invalidQty() && (
        <QtyErrorText>
          Item already has {claimedQty}/{_item?.quantity} claimed. Cannot reduce quantity below{' '}
          {claimedQty}.
        </QtyErrorText>
      )}

      <Accordion
        isOpen={!!item.category}
        className="border-t border-neutral-stone"
        header={
          <div className="flex flex-wrap">
            <div>Menu Section</div>
            <div className="text-sm w-full">
              Tag specific items together to create a collapsable menu section. (Not required.)
            </div>
          </div>
        }
      >
        <ItemCategories
          event={event}
          selected={item.category}
          customInputError={
            showCategoryError
              ? {
                  message: `${capitalize(
                    item.category?.name
                  )} category already exists for this event`,
                  type: 'validate'
                }
              : undefined
          }
          onSelect={(category) => {
            setShowCategoryError(false);
            if (category === item.category) {
              setItem({ ...item, category: undefined });
            } else {
              setItem({ ...item, category });
            }
          }}
          onCustomCategory={(categoryName) => {
            setShowCategoryError(false);
            if (categoryName) {
              setItem({ ...item, category: { id: 0, name: categoryName } });
            } else {
              setItem({ ...item, category: undefined });
            }
          }}
          onCustomCategoryClick={(categoryName) => {
            if (categoryName) {
              setItem({ ...item, category: { id: 0, name: categoryName } });
            } else {
              setItem({ ...item, category: undefined });
            }
          }}
        />
      </Accordion>

      <Accordion isOpen={!!item.note} header={<div>Notes</div>}>
        <textarea
          value={item.note}
          onChange={(e) => setItem({ ...item, note: e.target.value })}
          className="h-48 p-4 border rounded-lg"
          placeholder="Any helpful details or directions to add specific to this entry."
        />
      </Accordion>

      <div className="text-center pt-2 mt-2 border-t border-neutral-stone">
        <SaveButton
          outline
          disabled={!itemHasName || invalidQty()}
          onClick={async () => {
            try {
              if (item.category && item.category.id === 0) {
                const itemCategory = await fetcher(`/api/event/${event.id}/add-custom-category`, {
                  method: 'POST',
                  body: JSON.stringify({ name: item.category.name })
                });
                item.category.id = itemCategory.id;
              }
              onSave(item);
              onClose();
            } catch (e: any) {
              setShowCategoryError(true);
            }
          }}
        >
          Save
        </SaveButton>
      </div>
    </div>
  );
};
