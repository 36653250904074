import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { truncate } from 'lodash';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import tw from 'twin.macro';

import useEvent from '../../hooks/useEvent';
import Items from '../../pages/Events/Items';
import { Card } from '../Card';
import { Loader } from '../Loader';
import { Menu } from '../Menu';

const Header = styled.div`
  ${tw`text-base font-semibold text-center flex-1 self-center`}
`;

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: 50%;
  }
`;

const Pill = styled.div`
  ${tw`flex flex-1 text-sm bg-content-pearl px-2 rounded-2xl w-16 justify-center self-center`}
`;

const FadedLoader = styled.div`
  ${tw`fixed bottom-0 right-0 z-40 flex items-center justify-center w-full h-full bg-opacity-50 bg-neutral-soil opacity-50`};
  max-width: 30rem;

  animation: ${fadeIn} 0.5s ease-in;
`;

export function EditEventItems() {
  const [isSaving, setIsSaving] = useState(false);
  const { id } = useParams<{ id: string }>();
  const { event: _event } = useEvent(Number(id));
  const [event, setEvent] = useState(_event);
  const { save } = useEvent(Number(id));
  const router = useHistory();

  const maxTitleLength = 57;
  const title = truncate(`Event Page for ${event?.name}`, {
    length: maxTitleLength,
    omission: '...'
  });

  useEffect(() => {
    setEvent(_event);
  }, [_event]);

  if (!event) {
    return (
      <FadedLoader>
        <Loader />
      </FadedLoader>
    );
  }

  return (
    <>
      <Menu
        header={
          <>
            <div className="flex flex-1 flex-col">
              <Pill>Editing</Pill>
              <div className="flex justify-center -mr-6">
                <Header>
                  {title}
                </Header>
              </div>
            </div>
          </>
        }
        goBackOnClose
      >
        <Card className="flex flex-col flex-1 w-full space-y-6">
          <Items
            showPreviewEventButton={false}
            event={event}
            onChange={async (e) => {
              setIsSaving(true);
              await save(e, false);
              setIsSaving(false);
            }}
            onSubmit={async () => {
              setIsSaving(true);
              await save(event, false);
              setIsSaving(false);
              router.push(`/event/${event.id}`);
            }}
            saveButtonName={'Save'}
          />
        </Card>
      </Menu>
      {isSaving && (
        <FadedLoader>
          <Loader />
        </FadedLoader>
      )}
    </>
  );
}

export default EditEventItems;
