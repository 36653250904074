import 'react-quill/dist/quill.snow.css';
import '../../react-quill-indent-override.css';

import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useModal } from 'react-modal-hook';
import { useHistory } from 'react-router-dom';

import { Button, Loader, Modal, UnlockMembership } from '../../components';
import { PreviewEventLink } from '../../components/PreviewEventLink/PreviewEventLink';
import { useEvent } from '../../hooks';
import { Event, EventGuest, EventRole, SubscriptionType } from '../../models';
import { usePotluck, useUser } from '../../providers';

interface InvitesProps {
  event: Event;
  showPreviewEventButton?: boolean;
  onChange: (event: Event) => void;
  onSubmit: (event: Event) => Promise<void>;
}

export function Invites({
  event,
  onSubmit,
  onChange,
  showPreviewEventButton = true
}: InvitesProps) {
  const userProvider = useUser();
  const { save } = useEvent();
  const { user, checkout } = userProvider;
  const { organizing } = usePotluck();
  const hasCohosts = event.invites.some((i) => i.role === EventRole.Cohost);
  const isOrganizer = event?.organizer.id === user?.id;
  const eventNeedsPayment = !user?.subscriptionType && !event?.paidSingleEvent && isOrganizer;

  const router = useHistory();

  const { register, handleSubmit, getValues, setValue } = useForm({ defaultValues: event });

  useEffect(() => {
    // form is not actively editing the event#code, but it can be modified externally, so let's watch for that
    setValue('code', event.code);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event?.code]);

  const [openUnlockMembership, closeUnlockMembership] = useModal(
    () => (
      <Modal onClose={closeUnlockMembership} fullHeight>
        {() => (
          <UnlockMembership
            displaySingleOption={true}
            onSubmit={async (subscriptionType) => {
              const redirectBackHere = `${window.location.origin}/edit/${event.id}/confirmation`;
              await save(getValues(), true);
              subscriptionType === SubscriptionType.Single
                ? await checkout(subscriptionType, redirectBackHere, event.id)
                : await checkout(subscriptionType, redirectBackHere);
            }}
            userProvider={userProvider}
          />
        )}
      </Modal>
    ),
    [getValues, handleSubmit, user, userProvider]
  );

  if (!event.id) {
    router.push('/');
  }

  if (!user) {
    return <Loader />;
  }

  const isCoHost =
    event.guests &&
    event.guests.find((g: EventGuest) => g.guest.id === user.id)?.role === EventRole.Cohost;

  const displayDefaultAction = !!user.subscriptionType || isCoHost || event.paidSingleEvent;

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="space-y-8">
      <div className="flex flex-col space-y-8">
        <div className="flex flex-col">
          <div className="text-left pb-2">Invitation Message</div>
          <textarea
            {...register('guestMessage')}
            onChange={(e) => {
              onChange({ ...event, guestMessage: e.target.value });
            }}
            className="h-64 p-4 border rounded-lg border-neutral-ash"
            placeholder={'This will appear in the email and text invitations to your guests.'}
            maxLength={1200}
          />
        </div>

        {hasCohosts && (
          <>
            <div className="flex flex-col">
              <div className="text-left pb-2">Message to Co-hosts</div>
              <textarea
                {...register('cohostMessage')}
                maxLength={1200}
                className="h-64 p-4 border rounded-lg border-neutral-ash"
                placeholder="This will appear in the email and text invitations to your co-hosts."
              />
            </div>
          </>
        )}
      </div>

      <div className="flex flex-col space-y-6">
        <div className="text-center">
          {displayDefaultAction ? (
            <>
              <Button>Save & Send</Button>
            </>
          ) : (
            <>
              <Button
                onClick={async (e) => {
                  e.preventDefault();
                  openUnlockMembership();
                  organizing.setCreatingSingleEvent(false);
                }}
              >
                Activate Event
              </Button>
            </>
          )}
          <div className="text-center mt-1 italic">Invitations will be sent.</div>
        </div>
        {showPreviewEventButton && (
          <div className="self-center">
          <PreviewEventLink
            large={true}
            event={event}
            buttonText={eventNeedsPayment ? 'Preview Before Purchasing' : 'Preview Before Sending'}
          />
          </div>
        )}
        <Button outline inline inlineWide
          onClick={async (e) => {
            e.preventDefault();
            await save(getValues());
            router.push('/');
          }}
        >
          Save & Send Later
        </Button>
      </div>
      <div className="text-xs text-neutral-ash">
        By clicking "Send Invitations" you are granting Potluck permission to email and/or SMS message your
        guests on your behalf. Guests will be able to respond to your RSVP via links in the SMS
        message or email they receive.
      </div>
    </form>
  );
}

export default Invites;
