export interface User {
  id: number;
  email?: string;
  password?: string;
  firstName: string;
  lastName?: string;
  phoneNumber?: string;
  pictureUrl?: string;
  subscriptionType?: string;
  privacyRules: string[];
  hostNotificationExclusions: string[];
  guestNotificationExclusions: string[];
  role: {
    id: number;
    name: UserRole;
  };
  verifiedAt?: string;
}

export enum UserRole {
  Admin = 'Admin',
  User = 'User'
}

export enum SubscriptionType {
  Month = 'Month', // Associated with a 3 month recurring charge in stripe
  Year = 'Year',
  Lifetime = 'Lifetime',
  Single = 'Single'
}

export default User;
