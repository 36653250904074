import styled from '@emotion/styled';
import { ComponentProps, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useModal } from 'react-modal-hook';
import tw from 'twin.macro';

import useScreenSize from '../../hooks/useScreenSize';
import { Event, EventCategory, EventItem } from '../../models';
import { FadeIn } from '../animations';
import { Button } from '../Button';
import { Card } from '../Card';
import { CheckmarkWhite, EditIcon, Lodging, Transportation } from '../icons';
import { Checkbox, Input } from '../Input';
import { Modal } from '../Modal/Modal';

type ItemProps = Omit<ComponentProps<'div'>, 'onChange'> & {
  item: EventItem;
  event: Event;
  onChange: (item: EventItem) => void;
};

const Container = styled(FadeIn)`
  ${tw`flex flex-col space-y-8`}
`;

const Quantity = styled.div`
  ${tw`flex items-center space-x-4`}
`;

const QuantityInput = styled.div`
  max-width: 5rem;
`;

const InlineButton = styled.div`
  ${tw`flex items-center justify-center px-4 py-1 rounded-full text-neutral-char space-x-2 bg-content-mist`}
`;

const ExcursionCard = styled(Card)<{ selected?: boolean }>`
  ${tw`relative flex-1 p-4 pt-8 border cursor-pointer border-neutral-stone hover:bg-content-mist`}

  ${(p) => p.selected && tw`bg-content-mist`}
`;

const SpecialSelectionIndicator = styled.div<{ selected?: boolean }>`
  ${tw`absolute flex w-5 h-5 border-2 rounded-full left-2 top-2 border-neutral-black`}

  ${(p) => (!p.selected ? tw`bg-none` : tw`bg-neutral-black`)}
`;

export function Item({ item, event, onChange, children, ...props }: ItemProps) {
  const [enableQuantity, setEnableQuantity] = useState(!item.id || (item.id && !!item.quantity));
  const { width } = useScreenSize();

  const [openNote, closeNote] = useModal(
    () => (
      <Modal onClose={closeNote} fullHeight>
        {({ close }) => (
          <div className="flex flex-col space-y-8">
            <div>Item notes</div>
            <textarea
              value={item.note}
              onChange={(e) => onChange({ ...item, note: e.target.value })}
              className="h-48 p-4 border rounded-lg"
              placeholder="Type something"
            />
            <Button outline onClick={close}>
              Save
            </Button>
          </div>
        )}
      </Modal>
    ),
    [item, onChange]
  );

  useEffect(() => {
    if (!enableQuantity) {
      onChange({ ...item, customMeasurement: undefined, quantity: undefined });
      setValue('quantity', undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enableQuantity]);

  const {
    register,
    setValue,
    formState: { errors }
  } = useForm<EventItem>({ defaultValues: useMemo(() => item, [item]), mode: 'all' });

  if (
    (event.category === EventCategory.Excursion && item.name === 'Lodging') ||
    item.name === 'Transportation'
  ) {
    return (
      <ExcursionCard
        selected={!!item.quantity}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();

          if (!item) {
            return;
          }

          onChange?.({
            ...item,
            quantity: item.quantity ? 0 : event.adultGuestCount + event.childGuestCount || 1,
            customMeasurement: 'People'
          });
        }}
      >
        <SpecialSelectionIndicator selected={!!item.quantity}>
          {item.quantity ? <CheckmarkWhite className="text-xl" /> : null}
        </SpecialSelectionIndicator>
        <div className="text-center space-y-6">
          <div className="flex flex-col items-center space-y-2">
            <div
              className={`self-center p-2 text-4xl rounded-full ${
                item.name === 'Lodging' ? 'bg-content-shell' : 'bg-content-mist'
              }`}
            >
              {item.name === 'Lodging' ? <Lodging /> : <Transportation />}
            </div>
            <div className="font-semibold select-none">{item.name}</div>
          </div>
          <div
            className="flex items-center justify-center h-8 cursor-pointer select-none text-interaction select-none"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();

              if (!item) {
                return;
              }

              onChange?.({
                ...item,
                quantity: event.adultGuestCount + event.childGuestCount || 1
              });

              openNote();
            }}
          >
            {item.note ? (
              <InlineButton>
                <EditIcon /> <div>Edit Note</div>
              </InlineButton>
            ) : (
              '+ Note'
            )}
          </div>
        </div>
      </ExcursionCard>
    );
  }

  return (
    <Container {...props}>
      <Input
        value={item.name || ''}
        {...register('name', { required: 'Name is required.' })}
        placeholder={
          width < 490
            ? 'Enter an item, dish, or task'
            : 'Enter an item or dish they should bring, a task they should do, etc'
        }
        onChange={(e) => onChange({ ...item, name: e.target.value })}
        disableSubtlePlaceholder={width < 335}
        errors={errors.name}
      />
      <Quantity>
        <div className="flex-shrink-0">Set Max:</div>
        <QuantityInput>
          <Input
            {...register('quantity')}
            placeholder="Qty"
            type="number"
            value={item.quantity}
            disabled={!enableQuantity}
            min="0"
            onFocus={(e) => e.target.select()}
            onChange={(e) => onChange({ ...item, quantity: Number(e.target.value) })}
          />
        </QuantityInput>
        <Input
          disabled={!enableQuantity}
          placeholder="(e.g. platters, bags, cases)"
          value={item.customMeasurement || ''}
          onChange={(e) => onChange({ ...item, customMeasurement: e.target.value })}
        />
      </Quantity>
      <div className="flex flex-1">
        <Checkbox
          checked={!enableQuantity}
          onChange={(e) => {
            setEnableQuantity(!e.target.checked);
          }}
        />
        <div className="pl-2">No Maximum Amount</div>
      </div>
    </Container>
  );
}

export default Item;
